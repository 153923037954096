



















































































import Vue from 'vue';
import {Network, Organization} from '@stellarbeat/js-stellar-domain';
import {Component, Prop} from 'vue-property-decorator';
import Github from '@/components/organization/logo/github.vue';
import Twitter from '@/components/organization/logo/twitter.vue';
import {BAlert, BBadge, BIconExclamationCircle, BIconLink, BIconMap, BIconShield, VBTooltip, BIconEnvelope} from 'bootstrap-vue';
import Stellar from '@/components/organization/logo/stellar.vue';


@Component({
    components: {
        Stellar,
        Twitter,
        Github,
        BAlert: BAlert,
        BIconExclamationTriangle: BIconExclamationCircle,
        BBadge: BBadge,
        BIconLink: BIconLink,
        BIconShield: BIconShield,
        BIconMap: BIconMap,
        BIconEnvelope
    },
    directives: {'b-tooltip': VBTooltip}
})
export default class OrganizationProfile extends Vue {
    @Prop()
    organization!: Organization;

    get network(): Network {
        return this.$root.$data.store.network;
    }

    get store() {
        return this.$root.$data.store;
    }

    get notAllArchivesUpToDate() {
        return this.organization.validators
            .map(validator => this.$root.$data.store.network.getNodeByPublicKey(validator)!)
            .some(validator => {
                return (validator.historyUrl !== undefined && !validator.isFullValidator);
            });
    }


};
