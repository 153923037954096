




import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    name: 'twitter'
})
export default class Twitter extends Vue {
}
