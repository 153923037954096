





























































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Store from '@/store/Store';
import StellarCoreConfigurationGenerator
    from '@stellarbeat/js-stellar-domain/lib/stellar-core-configuration-generator';
import OrganizationValidatorsDropdown
    from '@/components/organization/sidebar/organization-validators-dropdown.vue';
import NavLink from '@/components/side-bar/nav-link.vue';
import SimulateNewNode from '@/components/node/tools/simulation/simulate-new-node.vue';
import SideBar from '@/components/side-bar/side-bar.vue';
import {BModal, VBModal, BBadge, VBTooltip} from 'bootstrap-vue';
import TrustedOrganizationsDropdown from '@/components/organization/sidebar/trusted-organizations-dropdown.vue';

@Component({
    components: {
        TrustedOrganizationsDropdown,
        SideBar,
        SimulateNewNode,
        NavLink,
        OrganizationValidatorsDropdown,
        BModal: BModal,
        BBadge
    },
    directives: {'b-modal': VBModal, 'b-tooltip': VBTooltip}
})
export default class OrganizationSideBar extends Vue {

    get store(): Store {
        return this.$root.$data.store;
    }

    get selectedOrganization() {
        return this.store.selectedOrganization;
    }

    get validators() {
        return this.selectedOrganization!.validators.map(validator => this.network.getNodeByPublicKey(validator)!);
    }

    get organizationType() {
        return this.selectedOrganization!.isTierOneOrganization ? 'T1 Organization' : 'Organization';
    }

    get network() {
        return this.store.network;
    }

    get tomlNodesExport() {
        let stellarCoreConfigurationGenerator = new StellarCoreConfigurationGenerator(this.network);
        return stellarCoreConfigurationGenerator.nodesToToml(this.validators);
    }

    get failAt() {
        let nrOfValidatingNodes = this.validators
            .filter(validator => validator !== undefined)
            .filter(node => node!.isValidating).length;

        return nrOfValidatingNodes - this.selectedOrganization!.subQuorumThreshold + 1;
    }
}
