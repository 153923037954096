
























































































import {Component, Prop, Watch} from 'vue-property-decorator';
    import Vue from 'vue';
    import {
        Network,
        Node, NodeSnapShot,
        Organization,
        OrganizationSnapShot,
        PublicKey,
        QuorumSet
    } from '@stellarbeat/js-stellar-domain';
    import Store from '@/store/Store';
    //import AsyncComputed from 'vue-async-computed-decorator';
    import {Delta, formatters, create, DiffPatcher} from 'jsondiffpatch';
    import 'jsondiffpatch/dist/formatters-styles/html.css';


    import {
        VBTooltip,
        BTable,
        BModal,
        VBModal,
        BButton,
        BBadge,
        BListGroup,
        BListGroupItem,
        BIconFileDiff,
        BButtonGroup,
        BIconClock,
        BButtonToolbar,
        BIconExclamationTriangle
    } from 'bootstrap-vue';

    interface Update {
        key: string;
        value: any;
    }

    @Component({
        components: {
            BTable, BModal, BButton, BListGroup, BListGroupItem, BBadge, BIconFileDiff,
            BButtonGroup,
            BIconClock,
            BButtonToolbar,
            BIconExclamationTriangle
        },
        directives: {'b-tooltip': VBTooltip, 'b-modal': VBModal}
    })
    export default class OrganizationLatestUpdates extends Vue {
        protected differ!: DiffPatcher;
        protected diffModalHtml: string = '<p>No update selected</p>';
        protected deltas: Map<string, Delta | undefined> = new Map();
        protected updatesPerDate: { date: string, updates: Update[], snapshot: any }[] = [];
        protected isLoading: boolean = true;
        protected failed: boolean = false;
        protected snapShots:NodeSnapShot[] = [];
        @Prop()
        protected organization!: Organization;
        @Watch('organization')
        async onOrganizationChanged(){
            await this.getSnapshots();
        }
        showDiff(snapShot: any) {
            formatters.html.showUnchanged(true);
            this.diffModalHtml = formatters.html.format(this.deltas.get(snapShot.startDate)!, snapShot);
            (this.$refs['modal-diff'] as BModal).show();
        }

        get store(): Store {
            return this.$root.$data.store;
        }

        async getSnapshots() {
            let snapshots: any = [];
            try {
                this.deltas = new Map();
                this.updatesPerDate = [];
                snapshots = await this.store.fetchOrganizationSnapshotsById(this.organization.id);
                snapshots = snapshots.map((snapshot: OrganizationSnapShot) =>{
                    return {
                        validators: snapshot.organization.validators.map(
                            (validator: PublicKey) => this.network.getNodeByPublicKey(validator) && this.network.getNodeByPublicKey(validator)!.name ? this.network.getNodeByPublicKey(validator)!.name : validator),
                        startDate: snapshot.startDate,
                        endDate: snapshot.endDate,
                        id: snapshot.organization.id,
                        name: snapshot.organization.name,
                        dba: snapshot.organization.dba,
                        url: snapshot.organization.url,
                        officialEmail: snapshot.organization.officialEmail,
                        phoneNumber: snapshot.organization.phoneNumber,
                        physicalAddress: snapshot.organization.physicalAddress,
                        twitter: snapshot.organization.twitter,
                        github: snapshot.organization.github,
                        description: snapshot.organization.description,
                        keybase: snapshot.organization.keybase
                    }
                });
                let validatorSort = (a: PublicKey, b: PublicKey) => a.localeCompare(b);
                for (let i = snapshots.length - 2; i >= 0; i--) {
                    let updates: Update[] = [];
                    ['validators', 'name', 'dba', 'url', 'officialEmail', 'phoneNumber', 'physicalAddress', 'twitter', 'github', 'description', 'keybase']
                        .filter(key => key === 'validators' ?
                            JSON.stringify(snapshots[i][key].sort(validatorSort)) !== JSON.stringify(snapshots[i + 1][key].sort(validatorSort))
                            : snapshots[i][key] !== snapshots[i + 1][key]
                        )
                        .forEach(changedKey => updates.push({key: changedKey, value: snapshots[i][changedKey]}));

                    if (snapshots[i]['startDate'].getTime() !== snapshots[i + 1]['endDate'].getTime()) {
                        updates.push({key: 'archival', value: 'unArchived'});
                    }

                    this.updatesPerDate.push({date: snapshots[i].startDate, updates: updates, snapshot: snapshots[i]});

                    this.deltas.set(snapshots[i].startDate, this.differ.diff(snapshots[i + 1], snapshots[i]));
                }
                this.updatesPerDate.reverse();

            } catch (e) {
                this.isLoading = false;
                this.failed = true;
                console.log(e);
            }

            this.isLoading = false;
            return snapshots;
        }

        async timeTravel(update: any) {
            this.store.isLoading = true;
            await this.store.fetchData(new Date(update.startDate));
            this.store.isLoading = false;
        }

        async mounted() {
            this.differ = create({
                /*propertyFilter: function (name: string, context: any) {
                    return !['startDate', 'endDate'].includes(name);
                },*/
            });
            this.snapShots = await this.getSnapshots();

        }

        get network(): Network {
            return this.store.network;
        }
    };
