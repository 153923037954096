




































import {Component, Prop, Mixins} from 'vue-property-decorator';
import {Node, Organization} from '@stellarbeat/js-stellar-domain';
import NavLink from '@/components/side-bar/nav-link.vue';
import {DropdownMixin} from '@/components/side-bar/dropdown-mixin';
import NavPagination from '@/components/side-bar/nav-pagination.vue';
import NodeActions from '@/components/node/sidebar/node-actions.vue';
import OrganizationActions from '@/components/organization/sidebar/organization-actions.vue';

@Component({
    components: {
        OrganizationActions,
        NodeActions,
        NavPagination,
        NavLink
    },
})
export default class OrganizationValidatorsDropdown extends Mixins(DropdownMixin) {
    @Prop()
    public organization!: Organization;

    get trustedOrganizations() {
        let trustedOrganizations = new Set<Organization>();
        this.organization.validators.forEach(publicKey => {
            let validator = this.network.getNodeByPublicKey(publicKey)!;
            this.network.getTrustedOrganizations(validator.quorumSet).forEach(org => {
                if(org.id !== this.organization.id)
                    trustedOrganizations.add(org)
            });
        })
        return Array.from(trustedOrganizations);
    }

    public selectOrganization(organization: Organization) {
        this.$router.push({
            name: 'organization-dashboard',
            params: {organizationId: organization.id},
            query: {'center': '1', 'no-scroll': '0', 'view': this.$route.query.view, 'network': this.$route.query.network, 'at': this.$route.query.at},
        });
    }
}
